import { FloatingOverlay as FloatingOverlayBase } from '@floating-ui/react'

import { useGlobalPageState } from '/machinery/useGlobalPageState'
import { useScrollDirection } from '/machinery/useScroll'

import { SubmenuContextProvider, useSubmenuContext } from '/features/pageOnly/menu/SubmenuContext'
import { MenuDesktop } from '/features/pageOnly/menu/MenuDesktop'
import { MenuMobile } from '/features/pageOnly/menu/MenuMobile'

import styles from './Header.css'

export function Header({ menuItems, transparentHeader, blackMenu, layoutClassName }) {
  return (
    <header className={cx(styles.component, layoutClassName)}>
      <SubmenuContextProvider>
        <Menu layoutClassName={styles.menuLayout} {...{ menuItems, transparentHeader, blackMenu }} />
      </SubmenuContextProvider>
    </header>
  )
}

function Menu({ menuItems, transparentHeader, blackMenu, layoutClassName }) {
  const { activeSubmenu } = useSubmenuContext()

  const [isActiveMobileMenu] = useGlobalPageState('mobile-menu-is-active')
  const desktopSubmenuIsActive = activeSubmenu.isActive && !isActiveMobileMenu

  const { classNames } = useMenuClassNames({ transparentHeader, blackMenu, activeSubmenu, isActiveMobileMenu, desktopSubmenuIsActive })

  return (
    <FloatingOverlayBase
      lockScroll={isActiveMobileMenu}
      className={cx(styles.componentMenu, isActiveMobileMenu && styles.isActiveMobileMenu, layoutClassName)}
    >
      <div className={cx(styles.menuContainer, desktopSubmenuIsActive && styles.desktopSubmenuIsActive, ...classNames)}>
        <MenuDesktop layoutClassName={styles.menuDesktopLayout} {...{ menuItems }} />
        <MenuMobile layoutClassName={styles.menuMobileLayout} {...{ menuItems }} />
      </div>
    </FloatingOverlayBase>
  )
}

function useMenuClassNames({ transparentHeader, blackMenu, activeSubmenu, isActiveMobileMenu, desktopSubmenuIsActive }) {
  const { scrollDirection, metScrollThreshold } = useScrollDirection({ deltaThreshold: 50, scrollThreshold: 15 })

  const scrollingUp = scrollDirection === -1
  const neutralScrolling = scrollDirection === 0

  const isScrolledDown = metScrollThreshold && scrollingUp
  const isVisible = scrollingUp || neutralScrolling || desktopSubmenuIsActive
  const isSolid = isScrolledDown || !transparentHeader
  const isDesktopSubmenuActive = activeSubmenu.isActive && !isActiveMobileMenu

  const classNames = [
    blackMenu && styles.blackMenu,
    isScrolledDown && styles.isScrolledDown,
    isVisible && styles.isVisible,
    isSolid && styles.isSolid,
    isDesktopSubmenuActive && styles.isDesktopSubmenuActive,
    isActiveMobileMenu && styles.isActiveMobileMenu
  ]

  return { classNames }
}
