import { useLinkProtocols } from '/machinery/useLinkProtocols'

import { LabelUnderline } from '/features/buildingBlocks/LabelUnderline'

import styles from './MenuLink.css'

export function MenuLink({ href, dataX, label, isCurrentLocation, itemProps = undefined, layoutClassName = undefined }) {
  const { rel, target } = useLinkProtocols({ href })

  return (
    <a
      data-x={dataX}
      className={cx(styles.component, isCurrentLocation && styles.isCurrentLocation, layoutClassName)}
      {...{ href, rel, target }}
      {...itemProps}
    >
      <LabelUnderline {...{ label }} />
    </a>
  )
}
