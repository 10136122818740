import { useFloating, useTransitionStyles, useDismiss, useInteractions, autoUpdate } from '@floating-ui/react'
import { useLocation } from '@kaliber/routing'

import { routeMap } from '/routeMap'
import { useLanguage } from '/machinery/I18n'
import { useGlobalPageState } from '/machinery/useGlobalPageState'
import { useNormalizeLink, useIsCurrentLocationSubmenu } from '/machinery/useNormalizeLink'

import { useSubmenuContext } from '/features/pageOnly/menu/SubmenuContext'
import { ContainerLg } from '/features/buildingBlocks/Container'
import { LinkLogo } from '/features/buildingBlocks/LinkLogo'
import { Hamburger } from '/features/pageOnly/menu/Hamburger'
import { MenuLink } from '/features/pageOnly/menu/MenuLink'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './MenuMobile.css'

import chevronIcon from '/images/icons/chevron-down.raw.svg'

export function MenuMobile({ menuItems, layoutClassName }) {
  const [isOpen, setIsOpenMobileMenu] = useGlobalPageState('mobile-menu-is-active')
  const { referenceProps, floatingProps, isMounted } = useFloatingProps({ isOpen, onOpenChange: handleOpenChange })

  const showMenu = Boolean(menuItems?.length)

  return (
    <nav className={cx(styles.component, layoutClassName)} {...referenceProps}>
      <ContainerLg>
        <MenuBar onOpenChange={handleOpenChange} {...{ showMenu }} />
      </ContainerLg>

      {isMounted && (
        <div className={styles.menuContainer}>
          <ul aria-hidden={!isOpen} className={styles.menuList} {...floatingProps}>
            {showMenu && menuItems?.map((item, i) => (
              <MenuItem key={i} {...{ item }} />
            ))}
          </ul>
        </div>
      )}
    </nav>
  )

  function handleOpenChange() {
    setIsOpenMobileMenu(!isOpen)
  }
}

function MenuBar({ showMenu, onOpenChange }) {
  const language = useLanguage()

  const [isOpen] = useGlobalPageState('mobile-menu-is-active')

  return (
    <div className={styles.componentMenuBar}>
      <div className={styles.logoContainer}>
        <LinkLogo href={routeMap.app.home({ language })} />
      </div>

      {showMenu && (
        <Hamburger onClick={onOpenChange} layoutClassName={styles.hamburgerLayout} {...{ isOpen }} />
      )}
    </div>
  )
}

function MenuItem({ item }) {
  const { submenu, label } = item

  return submenu?.length
    ? <MenuItemSubmenu id={`${item._key}_${label}`} {...{ label, submenu }} />
    : <MenuItemLink {...{ label, item }} />
}

function MenuItemLink({ label, item }) {
  const location = useLocation()
  const { href, isCurrentLocation } = useNormalizeLink({ item, location })

  return (
    <li className={styles.componentMenuItemLink}>
      <MenuLink dataX='link-in-menu' {...{ href, label, isCurrentLocation }} />
    </li>
  )
}

function MenuItemSubmenu({ id, label, submenu }) {
  const location = useLocation()
  const { isCurrentLocation } = useIsCurrentLocationSubmenu({ submenu, location })
  const { activeSubmenu, onActiveSubmenuChange } = useSubmenuContext()

  const thisSubmenuIsActive = id === activeSubmenu.id && activeSubmenu.isActive

  return (
    <li className={styles.componentMenuItemSubmenu} data-x-context={label}>
      <button type='button' onClick={handleActiveSubmenuChange} className={styles.submenuButton}>
        <span className={cx(styles.submenuButtonLabel, isCurrentLocation && styles.isCurrentLocation)}>{label}</span>
        <span className={cx(styles.iconContainer, thisSubmenuIsActive && styles.isActive)}>
          <Icon icon={chevronIcon} layoutClassName={styles.iconLayout} />
        </span>
      </button>

      <div className={cx(styles.submenuContainer, thisSubmenuIsActive && styles.isActive)}>
        <Submenu items={submenu} isActive={thisSubmenuIsActive} />
      </div>
    </li>
  )

  function handleActiveSubmenuChange() {
    onActiveSubmenuChange({ id, isActive: thisSubmenuIsActive ? !activeSubmenu.isActive : true })
  }
}

function Submenu({ items, isActive }) {
  return (
    <ul className={cx(styles.componentSubmenu, isActive && styles.isActive)}>
      {items.map((item, i) => <SubmenuItemLink key={i} index={i} {...{ item, isActive }} />)}
    </ul>
  )
}

function SubmenuItemLink({ index, item, isActive }) {
  const location = useLocation()
  const { href, isCurrentLocation } = useNormalizeLink({ item, location })

  const { label } = item

  return (
    <li style={{ '--index': index + 1 }} className={cx(styles.componentSubmenuItemLink, isActive && styles.isActive)}>
      <MenuLink dataX='link-in-submenu' {...{ href, label, isCurrentLocation }} />
    </li>
  )
}

function useFloatingProps({ isOpen, onOpenChange }) {
  const { strategy, refs, context } = useFloating({
    strategy: 'fixed',
    open: isOpen,
    onOpenChange,
    whileElementsMounted: autoUpdate,
  })

  const dismiss = useDismiss(context, {
    referencePress: false,
    outsidePress: true
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss])

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    common: { transform: 'translateX(100%)', transformOrigin: 'right' },
    open: { transform: 'translateX(0)' },
    close: { transform: 'translateX(100%)' },
    duration: 500,
  })

  const style = {
    position: strategy,
    ...transitionStyles
  }

  return {
    referenceProps: { ...getReferenceProps(), ref: refs.setReference },
    floatingProps: { ...getFloatingProps(), ref: refs.setFloating, style },
    isMounted
  }
}
