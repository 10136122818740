import { useFloating, useDismiss, useInteractions, useHover, autoUpdate, shift, useRole, useFocus, safePolygon } from '@floating-ui/react'
import { useLocation } from '@kaliber/routing'

import { routeMap } from '/routeMap'
import { useLanguage } from '/machinery/I18n'
import { useNormalizeLink, useIsCurrentLocationSubmenu } from '/machinery/useNormalizeLink'

import { useSubmenuContext } from '/features/pageOnly/menu/SubmenuContext'
import { ContainerXl } from '/features/buildingBlocks/Container'
import { LinkLogo } from '/features/buildingBlocks/LinkLogo'
import { MenuLink } from '/features/pageOnly/menu/MenuLink'
import { Icon } from '/features/buildingBlocks/Icon'
import { LabelUnderline } from '/features/buildingBlocks/LabelUnderline'

import styles from './MenuDesktop.css'

import chevronIcon from '/images/icons/chevron-down.raw.svg'

export function MenuDesktop({ menuItems, layoutClassName }) {
  return (
    <nav className={cx(styles.component, layoutClassName)}>
      <ContainerXl>
        <Menu {...{ menuItems }} />
      </ContainerXl>

      <Background layoutClassName={styles.backgroundLayout} />
    </nav>
  )
}

function Background({ layoutClassName }) {
  const { activeSubmenu } = useSubmenuContext()
  const { isActive, totalMenuHeight } = activeSubmenu

  return (
    <span
      style={{ height: isActive ? totalMenuHeight : 0 }}
      className={cx(styles.componentBackground, isActive && styles.isActive, layoutClassName)}
    />
  )
}

function Menu({ menuItems }) {
  const language = useLanguage()

  return (
    <ul className={styles.componentMenu}>
      <div className={styles.logoContainer}>
        <LinkLogo href={routeMap.app.home({ language })} />
      </div>

      {Boolean(menuItems?.length) && menuItems.map((item, i) => (
        <MenuItem key={i} layoutClassName={styles.menuItemLayout} {...{ item }} />
      ))}
    </ul>
  )
}

function MenuItem({ item, layoutClassName }) {
  const { label, submenu } = item

  return submenu?.length
    ? <MenuItemSubmenu id={`${item._key}_${label}`} {...{ label, submenu, layoutClassName }} />
    : <MenuItemLink {...{ label, item, layoutClassName }} />
}

function MenuItemLink({ label, item, layoutClassName }) {
  const location = useLocation()
  const { href, isCurrentLocation } = useNormalizeLink({ item, location })

  return (
    <li className={cx(styles.componentMenuItemLink, layoutClassName)}>
      <MenuLink dataX='link-in-menu' layoutClassName={styles.linkLayout} {...{ href, label, isCurrentLocation }} />
    </li>
  )
}

function MenuItemSubmenu({ id, label, submenu, layoutClassName }) {
  const location = useLocation()
  const { isCurrentLocation } = useIsCurrentLocationSubmenu({ submenu, location })
  const { referenceProps, floatingProps, itemProps } = useFloatingProps({ id })
  const { activeSubmenu } = useSubmenuContext()

  const thisSubmenuIsActive = id === activeSubmenu.id && activeSubmenu.isActive

  return (
    <>
      <li className={cx(styles.menuItemSubmenu, layoutClassName)} data-x-context={label}>
        <button className={styles.submenuButton} {...referenceProps}>
          <span className={cx(styles.submenuButtonLabel, isCurrentLocation && styles.isCurrentLocation)}>
            <LabelUnderline {...{ label }} />
          </span>
          <span className={cx(styles.iconContainer, thisSubmenuIsActive && styles.isActive)}>
            <Icon icon={chevronIcon} />
          </span>
        </button>
      </li>

      <div {...floatingProps} className={cx(styles.submenuContainer, thisSubmenuIsActive && styles.isActive)}>
        <Submenu items={submenu} isActive={thisSubmenuIsActive} {...{ itemProps }} />
      </div>
    </>
  )
}

function Submenu({ items, isActive, itemProps }) {
  return (
    <ul className={styles.componentSubmenu}>
      {items.map((item, i) => <SubmenuItemLink key={i} index={i + 1} {...{ item, isActive, itemProps }} />)}
    </ul>
  )
}

function SubmenuItemLink({ index, item, isActive, itemProps }) {
  const location = useLocation()
  const { href, isCurrentLocation } = useNormalizeLink({ item, location })

  const { label } = item

  return (
    <li style={{ '--index': index }} className={cx(styles.componentSubmenuItemLink, isActive && styles.isActive)}>
      <MenuLink dataX='link-in-submenu' layoutClassName={styles.linkLayout} {...{ href, label, isCurrentLocation, itemProps }} />
    </li>
  )
}

function useFloatingProps({ id }) {
  const { activeSubmenu, onActiveSubmenuChange } = useSubmenuContext()

  const { y, refs, context, middlewareData } = useFloating({
    open: activeSubmenu.isActive && activeSubmenu.id === id,
    onOpenChange: handleActiveSubmenuChange,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [shift(), position(), dimensions()]
  })

  const style =/** @type const */ ({
    position: 'fixed',
    top: y ?? 0,
    left: 0,
    width: '100%',
    paddingLeft: middlewareData?.position?.x
  })

  const hover = useHover(context, { handleClose: safePolygon({ requireIntent: false }) })
  const focus = useFocus(context)
  const role = useRole(context, { role: 'menu' })
  const dismiss = useDismiss(context, {
    referencePress: false,
    outsidePress: false
  })

  const { getReferenceProps, getItemProps, getFloatingProps } = useInteractions([
    hover, focus, role, dismiss
  ])

  function handleActiveSubmenuChange(isActive) {
    onActiveSubmenuChange({
      id,
      isActive,
      totalMenuHeight: middlewareData.dimensions?.totalMenuHeight
    })
  }

  return {
    referenceProps: { ...getReferenceProps(), ref: refs.setReference },
    floatingProps: { ...getFloatingProps(), ref: refs.setFloating, style },
    itemProps: getItemProps()
  }
}

function position() {
  return {
    name: 'position',
    fn: ({ rects }) => ({
      data: { x: rects.reference.x }
    }),
  }
}

function dimensions() {
  return {
    name: 'dimensions',
    fn: ({ rects }) => ({
      data: { totalMenuHeight: rects.reference.y + rects.reference.height + rects.floating.height }
    }),
  }
}
