import { Icon } from '/features/buildingBlocks/Icon'

import styles from './LinkLogo.css'

import logo from '/images/logo-team.raw.svg'

export function LinkLogo({ href }) {
  return (
    <a data-x='link-in-menu-to-home' className={styles.component} {...{ href }}>
      <Icon icon={logo} />
    </a>
  )
}
