const SubmenuContext = React.createContext(null)

export function SubmenuContextProvider({ children }) {
  const [activeSubmenu, setActiveSubmenu] = React.useState({ id: '', isActive: false, totalMenuHeight: 0 })

  const value = React.useMemo(
    () => ({
      activeSubmenu,
      onActiveSubmenuChange({ id, isActive, totalMenuHeight }) {
        setActiveSubmenu({ id, isActive, totalMenuHeight })
      }
    }),
    [activeSubmenu]
  )

  return <SubmenuContext.Provider {...{ children, value }} />
}

export function useSubmenuContext() {
  return React.useContext(SubmenuContext)
}
