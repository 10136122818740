import styles from './Hamburger.css'

export function Hamburger({ isOpen, onClick, layoutClassName }) {
  return <HamburgerBase {...{ isOpen, onClick, layoutClassName }} />
}

export function HamburgerWithReference({ isOpen, getReferenceProps, layoutClassName }) {
  return <HamburgerBase {...{ isOpen, getReferenceProps, layoutClassName }} />
}

function HamburgerBase({ isOpen, onClick = undefined, getReferenceProps = undefined, layoutClassName }) {
  return (
    <button
      type='button'
      aria-label='Menu'
      aria-expanded={isOpen}
      data-x={isOpen ? 'close-menu' : 'open-menu'}
      className={cx(styles.componentBase, layoutClassName)}
      {...getReferenceProps?.()}
      {...{ onClick }}
    >
      <span className={cx(styles.hamburger, isOpen && styles.isOpen)} />
    </button>
  )
}
