import { LocationProvider } from '@kaliber/routing'

import { routeMap } from '/routeMap'

import { Header } from './Header'

export default function HeaderApp({ location, menuItems, transparentHeader, blackMenu, layoutClassName }) {
  return (
    <LocationProvider initialLocation={location} {...{ routeMap }}>
      <Header {...{ menuItems, transparentHeader, blackMenu, layoutClassName }} />
    </LocationProvider>
  )
}
