import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { stringifyQueryString } from '@kaliber/sanity-routing/queryString'

import { routeMap } from '/routeMap'

export function useNormalizeLink({ item, location }) {
  return React.useMemo(
    () => {
      const isInternalLink = item?.link?._type === 'internalLink'

      const href = isInternalLink && item?.link?.ref
        ? determineDocumentPathSync({ document: item?.link?.ref, routeMap })
        : item?.href

      return {
        href: href || '#',
        isCurrentLocation: href === location.pathname,
        target: (isInternalLink) ? '_self' : '_blank'
      }
    },
    [item, location]
  )
}

export function useIsCurrentLocationSubmenu({ submenu, location }) {
  const isCurrentLocation = submenu.some(x => {
    const isInternalLink = x.link._type === 'internalLink'

    const href = isInternalLink && x.link.ref
      ? determineDocumentPathSync({ document: x.link.ref, routeMap })
      : x.href

    return href === location.pathname
  })

  return { isCurrentLocation }
}

export function normalizeLinkWithLabel(item, _) {
  if (item._type === 'internalLink') {
    return {
      href: item.ref ? determineDocumentPathSync({ document: item.ref, routeMap }) : '#',
      label: item.label,
      target: '_self'
    }
  }

  if (item._type === 'externalLink') {
    return {
      href: item.href,
      label: item.label,
      target: '_blank'
    }
  }
}

export function normalizeLink(item) {
  if (item._type === 'internalLink') {
    return {
      href: determineDocumentPathSync({ document: item.ref, routeMap }),
      target: '_self'
    }
  }

  if (item._type === 'externalLink') {
    return {
      href: item.href,
      target: '_blank'
    }
  }
}
